import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, TypeLanguage, TypeMediaFileEnum } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogTranslateTicketComponent } from 'app/dialog/dialog-translate-ticket/dialog-translate-ticket.component';
import { DialogTrimingComponent } from 'app/dialog/dialog-triming/dialog-triming.component';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { SpotDetails } from 'app/model/entity/ticket-editor/spot-details';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MediaService } from 'app/service/media.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { Spot } from './../../../model/entity/ticket-editor/spot';
import { TicketInformation } from './../../../model/entity/ticket-editor/ticket-information';
@Component({
  selector: 'ticket-editor-tab-spot',
  templateUrl: './ticket-editor-tab-spot.component.html',
  styleUrls: ['./ticket-editor-tab-spot.component.scss']
})
export class TicketEditorTabSpotComponent implements OnInit, OnDestroy {
  @ViewChild('spotId') spotIdElementRef: ElementRef;
  @ViewChild('spotName') spotNameElementRef: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('bottom') bottom!: ElementRef;
  @ViewChild('theFirstPositionOfTheSpotList') theFirstPositionOfTheSpotList!: ElementRef;
  @ViewChild('listTicket') listTicket: ElementRef;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  spotDetailSelected: SpotDetails; // spot Deatail Selected
  ticketInformationSelected: TicketInformation[]; // ticket infomation selected
  spotDetailSelectedCloneDepp: SpotDetails; // spot Deatail Selected clone deep
  spotSelectedCloneDepp: Spot; // spot Selected clone deep
  ticketInformationSelectedCloneDeep: TicketInformation[]; // ticket infomation selected clone deep
  spotSelected: Spot; // spot Selected
  placeholderSearch: string; // Set text placeholder input search
  listSpot: Spot[];
  listSpotDetails: Array<SpotDetails>; // list Spot Details
  appSelected: string;
  listSpotCloneDeppFirst: Spot[];
  listSpotCloneDeppSecond: Spot[];
  public languageKey: string; //language key
  TypeLanguage = TypeLanguage;
  isEditSpot: boolean;
  listApp: ApplicationDTO[] = []; // list Application
  languages: any = new Array<any>();
  dataFilter = []; // List ticket after filter
  inputSearch = Constant.EMPTY; // Value input search
  labelAddImage: string; //label Add Image
  labelTrimmingImage: string; //label Add Image
  image: any; // image
  imageCircle: any; // image Circle
  base64Selected: any;
  informationAccount: any;
  languageSelected: string;
  /**
   * Image types allowed
   */
  private imageTypesAllowed = [TypeMediaFileEnum.JPG, TypeMediaFileEnum.PNG, TypeMediaFileEnum.JPEG];
  acceptFileTypes: string = '.jpg, .png, .jpeg';
  listPropValidate: string[] = [];
  isDuplicate: boolean;
  private readonly IMAGE_NAME_TS = 'spot';
  private readonly IMAGE_NAME_TS_TRIMING = 'spot_circle';
  private readonly MODE_GETFILENAMEORIFINAL = 1;
  tickets: Array<Ticket> = new Array<Ticket>(); // list ticket
  fileData: any;
  commonObject: Common;
  mapImageEnvironmentVariables: Map<string, number> = new Map<string, number>();

  constructor(
    private menuActionService: MenuActionService,
    private translateService: TranslateService,
    private dataService: DataService,
    private dialogService: DialogService,
    public datepipe: DatePipe,
    private commonService: CommonService,
    private mediaService: MediaService,
    private ticketService: TicketEditorService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef
  ) {
    this.placeholderSearch = this.translateService.instant('ticket-editor.spot-tab.search');
    this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.image');
    this.labelTrimmingImage = this.translateService.instant('ticket-editor.spot-tab.trimming');
    // Action add
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorTabSpotComponent]) {
          this.addNewSPot();
        }
      }),
      // Action edit
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorTabSpotComponent]) {
          this.editSpot();
        }
      }),
      // Action Delete
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorTabSpotComponent]) {
          this.deleteSpot();
        }
      }),

      // Action duplicate
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorTabSpotComponent]) {
          this.duplicate();
        }
      }),
      this.menuActionService.actionTranslateTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorTabSpotComponent]) {
          this.translateTicket();
        }
      })
    );

    // Get language
    this.translateService.onLangChange.subscribe(() => {
      // Translate title
      this.placeholderSearch = this.translateService.instant('ticket-editor.spot-tab.search');
      this.labelAddImage = this.translateService.instant('ticket-editor.spot-tab.image');
      this.labelTrimmingImage = this.translateService.instant('ticket-editor.spot-tab.trimming');
    });

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
      })
    );
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
    this.listSpot = [];
    await this.getInformationAccount();
    await this.getAllApplication();
    this.dataFilter = this.listSpot;
    this.appSelected = _.cloneDeep(this.listApp[0]?.appId);
    this.changeAppId(this.listApp[0]?.appId);
    this.getInformationAccount();
    this.ticketService.getImageEnvironmentVariables().subscribe(res => {
      this.mapImageEnvironmentVariables = new Map<string, number>(Object.entries(res));
    });
  }

  ngOnDestroy(): void {
    this.isEditSpot = undefined;
    this.setViewInfoSpot();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * getInformationAccount
   */
  getInformationAccount(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.ticketService.getInformationAccount().subscribe(
        data => {
          this.informationAccount = data;
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }
  /**
   * selectSpot
   * @param spot
   * @param event
   * @param isNotReload
   */
  selectSpot(spot: Spot, event, isNotReload?: boolean): void {
    this.image = undefined;
    this.imageCircle = undefined;
    this.base64Selected = undefined;
    this.fileData = undefined;
    if ((this.spotSelected == spot && !isNotReload) || event?.target?.id === 'checkBoxSpot' || this.spotSelected?.isEdit) {
      return;
    }
    if (spot.spotDetails.spotQrCode && !spot.spotDetails.qrBase64) {
      this.ticketService.generateQRCode(spot.spotDetails.spotQrCode).subscribe(data => {
        this.base64Selected = data;
        spot.spotDetails.qrBase64 = this.sanitizer.bypassSecurityTrustHtml(this.base64ToSvg(this.base64Selected));
        spot.spotDetails.dataQr = data;
        this.fillValueSpotDetails(spot);
      });
    } else {
      this.base64Selected = spot.spotDetails.dataQr;
      this.fillValueSpotDetails(spot);
    }
  }

  /**
   * fillValueSpotDetails
   * @param spot
   */
  fillValueSpotDetails(spot: Spot) {
    this.spotSelected = spot;
    this.spotDetailSelected = spot.spotDetails;
    this.spotSelectedCloneDepp = _.cloneDeep(this.spotSelected);
    this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
    this.ticketInformationSelected = spot.ticketInformation;
    for (let i = 0; i < this.ticketInformationSelected.length; i++) {
      this.ticketInformationSelected[i].id = i;
    }
    this.listTicket.nativeElement.scrollTop = 0;
    this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
  }

  private base64ToSvg(base64: string | null): string | null {
    return base64;
  }

  /**
   * add new spot
   */
  addNewSPot(): void {
    this.isEditSpot = true;

    let temp = {};
    this.languages.forEach(element => {
      temp[element.translation_language_code] = '';
    });

    let spotNew = new Spot(null, null, _.cloneDeep(temp));
    let spotDetailsNew = new SpotDetails();
    spotDetailsNew.spotName = temp;
    spotDetailsNew.isShowSpotName = true;
    spotNew.spotDetails = spotDetailsNew;

    let ticketInfoNew = new TicketInformation();
    ticketInfoNew.ticketName = _.cloneDeep(temp);

    let ticketInformation = new TicketInformation(0, null, _.cloneDeep(temp), _.cloneDeep(temp), true, true);
    spotNew.ticketInformation = [ticketInformation];
    this.listSpot.push(spotNew);
    this.listPropValidate.push('tiedTicketId0');
    this.languages.forEach(element => {
      this.listPropValidate.push(`0tiedTicketNote${element.translation_language_code}`);
    });
    this.selectSpot(spotNew, null);
    this.setViewInfoSpot();
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * save spot
   */
  async saveSpot(): Promise<void> {
    if (this.validateBeforeSave()) {
      return;
    }
    if (!this.isDuplicate) {
      if (this.spotSelected.spotId) {
        if (this.image && this.imageCircle) {
          const image1$ = await Helper.convertFileToBase64(this.image as File);
          const imageCircle$ = await Helper.convertFileToBase64(this.imageCircle as File);
          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];
          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.updateSpot();
            })
            .catch(error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              // this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
              // this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
              // this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
              return;
            });
        } else {
          this.updateSpot();
        }
      } else {
        if (this.image && this.imageCircle) {
          const image1$ = await Helper.convertFileToBase64(this.image as File);
          const imageCircle$ = await Helper.convertFileToBase64(this.imageCircle as File);
          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];

          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.createSpot();
              this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
            })
            .catch(error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              // this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
              // this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
              // this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
              return;
            });
        } else {
          this.createSpot();
        }
      }
    } else {
      if (!this.spotDetailSelected.spotImage) {
        this.createSpot();
      } else {
        if (this.image && this.imageCircle) {
          const image1$ = await Helper.convertFileToBase64(this.image as File);
          const imageCircle$ = await Helper.convertFileToBase64(this.imageCircle as File);
          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];

          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.createSpot();
              this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
            })
            .catch(error => {
              Helper.getErrorTicket(error, this.translateService, this.dialogService);
              return;
            });
        } else {
          let image1$;
          let imageCircle$;
          const res1 = await new Promise<any>((resolve, reject) => {
            this.mediaService.getFileFromUrl(this.spotSelected.spotDetails.spotImageURL).subscribe(resolve, reject);
          });

          const res2 = await new Promise<any>((resolve, reject) => {
            this.mediaService.getFileFromUrl(this.spotSelected.spotDetails.spotImageCircleURL).subscribe(resolve, reject);
          });

          const file1 = new File([res1.body], 'image.png', {
            type: 'image/jpeg'
          });
          const file2 = new File([res2.body], 'image.png', {
            type: 'image/jpeg'
          });

          image1$ = await Helper.convertFileToBase64(file1);
          imageCircle$ = await Helper.convertFileToBase64(file2);

          let payload1 = {
            image: image1$,
            uploadImageType: 'spots'
          };
          let payload2 = {
            image: imageCircle$,
            uploadImageType: 'spots'
          };

          const uploadPromises = [
            this.ticketService.postImage(this.informationAccount, payload1).toPromise(),
            this.ticketService.postImage(this.informationAccount, payload2).toPromise()
          ];

          Promise.all(uploadPromises)
            .then(responses => {
              this.spotDetailSelected.spotImage = Helper.getFileNameFromUrl(responses[0]?.filePath);
              this.spotDetailSelected.imagePath = responses[0]?.filePath;
              this.spotDetailSelected.spotImageCircle = Helper.getFileNameFromUrl(responses[1]?.filePath);
              this.spotDetailSelected.imageCirclePath = responses[1]?.filePath;
              this.createSpot();
            })
            .catch(error => {
              // Helper.getErrorTicket(error, this.translateService, this.dialogService);
              // this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
              // this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
              // this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
              return;
            });
        }
      }
    }
  }

  /**
   * updateSpot
   * @returns
   */
  updateSpot(): void {
    const index = this.listSpot.findIndex(spot => spot.spotId == this.spotSelected.spotId);
    if (index == -1) {
      return;
    }
    const spotName = this.filterPropertyByLanguages(this.spotDetailSelected.spotName, this.languages);
    const ticketList = this.ticketInformationSelected.map(ticket => ({
      ticketId: +ticket.ticketId,
      note: this.filterPropertyByLanguages(ticket.note, this.languages)
    }));
    let payload = {
      spotId: this.spotSelected.spotId ?? null,
      appId: this.appSelected ?? null,
      spotName: this.spotDetailSelected.spotName ? spotName : null,
      ticketList: ticketList.length ? ticketList : null,
      imagePath: this.spotDetailSelected.imagePath ?? null,
      imageCirclePath: this.spotDetailSelected.imageCirclePath ?? null,
      cropPosition: this.spotDetailSelected.cropPosition ?? null
    };
    this.ticketService.putSpot(this.informationAccount, payload).subscribe(
      spotId => {
        this.isEditSpot = false;
        this.setViewInfoSpot();
        this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
        this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
          this.listSpot = Helper.convertSpots(data);
          this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
          if (!this.dataFilter || this.dataFilter.length == 0) {
            this.spotSelected = null;
            this.spotDetailSelected = null;
            this.ticketInformationSelected = null;
          } else {
            if (this.inputSearch?.length > 0) {
              this.searchSpot(this.inputSearch);
            }
            if (this.dataFilter.length > 0) {
              const index = this.dataFilter.findIndex(spot => spot.spotId == spotId.spotId);
              if (index == -1) {
                this.selectSpot(this.dataFilter[Constant.FIRST_ELEMENT_INDEX], null);
              } else {
                this.selectSpot(this.dataFilter[index], null);
              }
              this.spotDetailSelected.isShowSpotName = false;
              this.ticketInformationSelected?.forEach(e => {
                e.isShowTicketName = false;
                e.isShowNote = false;
              });
              this.spotSelectedCloneDepp = _.cloneDeep(this.spotSelected);
              this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
              this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
              this.image = undefined;
              this.imageCircle = undefined;
            } else {
              this.spotSelected = null;
              this.spotDetailSelected = null;
              this.ticketInformationSelected = null;
            }
          }
          this.listPropValidate = [];
          this.listPropValidate = [];
          this.languages.forEach(element => {
            this.listPropValidate.push(`SpotName${element.translation_language_code}`);
          });
        });
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        // this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
        // this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        // this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
      }
    );
  }

  /**
   * validateBeforeSave
   * @returns
   */
  validateBeforeSave(): boolean {
    this.listPropValidate.forEach(e => {
      this.validateSpot(e);
    });
    const properties = Object.keys(this.spotDetailSelected);
    for (let property of properties) {
      if (property.includes('isValidate') && this.spotDetailSelected[property]) {
        return true;
      }
    }
    return false;
  }

  /**
   * createSpot
   */
  createSpot(): void {
    const spotName = this.filterPropertyByLanguages(this.spotDetailSelected.spotName, this.languages);
    let payload = {
      spotName: spotName,
      appId: this.appSelected,
      ticketList: this.ticketInformationSelected.map(ticket => ({
        ticketId: +ticket.ticketId,
        note: this.filterPropertyByLanguages(ticket.note, this.languages)
      })),
      imagePath: this.spotDetailSelected.imagePath,
      imageCirclePath: this.spotDetailSelected.imageCirclePath,
      cropPosition: this.spotDetailSelected.cropPosition ?? null
    };
    this.ticketService.postSpot(this.informationAccount, payload).subscribe(
      spotId => {
        this.isEditSpot = false;
        this.setViewInfoSpot();
        this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
        this.isDuplicate = false;
        this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
          this.listSpot = Helper.convertSpots(data);
          this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
          if (!this.dataFilter || this.dataFilter.length == 0) {
            this.spotSelected = null;
            this.spotDetailSelected = null;
            this.ticketInformationSelected = null;
          } else {
            if (this.inputSearch?.length > 0) {
              this.searchSpot(this.inputSearch);
            }
            if (this.dataFilter?.length > 0) {
              const index = this.dataFilter.findIndex(spot => spot.spotId === spotId.spotId);
              if (index == -1) {
                this.selectSpot(this.dataFilter[Constant.FIRST_ELEMENT_INDEX], null);
              } else {
                this.selectSpot(this.dataFilter[index], null);
              }
              this.spotDetailSelected.isShowSpotName = false;
              this.ticketInformationSelected?.forEach(e => {
                e.isShowTicketName = false;
                e.isShowNote = false;
              });
              this.spotSelectedCloneDepp = _.cloneDeep(this.spotSelected);
              this.spotDetailSelectedCloneDepp = _.cloneDeep(this.spotDetailSelected);
              this.ticketInformationSelectedCloneDeep = _.cloneDeep(this.ticketInformationSelected);
            } else {
              this.spotSelected = null;
              this.spotDetailSelected = null;
              this.ticketInformationSelected = null;
            }
          }
          this.listPropValidate = [];
          this.languages.forEach(element => {
            this.listPropValidate.push(`SpotName${element.translation_language_code}`);
          });
        });
      },
      error => {
        Helper.getErrorTicket(error, this.translateService, this.dialogService);
        // this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
        // this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        // this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
      }
    );
  }

  /**
   * cancel save spot
   */
  cancelSaveSpot(): void {
    this.isEditSpot = false;
    this.setViewInfoSpot();
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
    if (!this.isDuplicate) {
      if (!this.spotSelected.spotId) {
        this.listSpot.pop();
        if (!this.listSpot || this.listSpot?.length == 0) {
          this.dataFilter = null;
          this.spotSelected = null;
          this.spotDetailSelected = null;
          this.ticketInformationSelected = null;
          this.fileData = undefined;
        } else {
          this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
          if (this.inputSearch?.length > 0) {
            this.searchSpot(this.inputSearch);
          }
          if (this.dataFilter?.length > 0) {
            this.selectSpot(this.dataFilter[Constant.FIRST_ELEMENT_INDEX], null);
          } else {
            this.dataFilter = null;
            this.spotSelected = null;
            this.spotDetailSelected = null;
            this.ticketInformationSelected = null;
            this.fileData = undefined;
          }
        }
      } else {
        const index = this.listSpot.findIndex(spot => spot.spotId == this.spotSelected.spotId);
        if (index == -1) {
          return;
        }
        this.spotSelected = _.cloneDeep(this.spotSelectedCloneDepp);
        this.spotDetailSelected = _.cloneDeep(this.spotDetailSelectedCloneDepp);
        this.ticketInformationSelected = _.cloneDeep(this.ticketInformationSelectedCloneDeep);
        this.listSpot[index] = _.cloneDeep(this.spotSelectedCloneDepp);
        this.listSpot[index].spotDetails = this.spotDetailSelectedCloneDepp;
        this.listSpot[index].ticketInformation = this.ticketInformationSelectedCloneDeep;
        this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
        if (this.inputSearch?.length > 0) {
          this.searchSpot(this.inputSearch);
        }
        if (this.dataFilter?.length > 0) {
          const indexFilter = this.dataFilter.findIndex(e => e.spotId == this.spotSelected.spotId);
          if (index == -1) {
            this.selectSpot(this.dataFilter[Constant.FIRST_ELEMENT_INDEX], null);
          } else {
            this.selectSpot(this.dataFilter[indexFilter], null);
          }
        } else {
          this.dataFilter = null;
          this.spotSelected = null;
          this.spotDetailSelected = null;
          this.ticketInformationSelected = null;
          this.fileData = undefined;
        }
      }
    } else {
      let idSDpotSelected = _.cloneDeep(this.spotSelected.spotId);
      this.listSpot.pop();
      this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
      if (this.inputSearch?.length > 0) {
        this.searchSpot(this.inputSearch);
      }
      if (this.dataFilter?.length > 0) {
        let indexSpotBeforeBeingDuplicated = this.dataFilter.findIndex(e => e.spotId == idSDpotSelected);
        if (indexSpotBeforeBeingDuplicated == -1) {
          this.selectSpot(this.dataFilter[Constant.FIRST_ELEMENT_INDEX], null);
        } else {
          this.selectSpot(this.dataFilter[indexSpotBeforeBeingDuplicated], null);
        }
      } else {
        this.dataFilter = null;
        this.spotSelected = null;
        this.spotDetailSelected = null;
        this.ticketInformationSelected = null;
        this.fileData = undefined;
      }

      this.isDuplicate = false;
    }

    this.listPropValidate = [];
    this.languages.forEach(element => {
      this.listPropValidate.push(`SpotName${element.translation_language_code}`);
    });

    if (this.spotDetailSelected) {
      this.spotDetailSelected.isShowSpotName = false;
    }
    if (this.ticketInformationSelected?.length) {
      this.ticketInformationSelected?.forEach(e => {
        e.isShowTicketName = false;
        e.isShowNote = false;
      });
    }
  }

  /**
   * validate spot when add new/ edit
   * @param prop
   */
  validateSpot(prop: string): void {
    switch (prop) {
      default:
        if (prop.includes('SpotName')) {
          let language = prop.slice('SpotName'.length);
          this.spotDetailSelected['isValidateRequiredSpotName' + language] = this.spotDetailSelected.spotName[language]?.trim().length
            ? false
            : true;

          if (
            this.spotDetailSelected['isValidateRequiredSpotName' + language] &&
            this.spotDetailSelected['isValidateLengthSpotName' + language]
          ) {
            this.spotDetailSelected['isValidateLengthSpotName' + language] = false;
          }
          if (!this.spotDetailSelected['isValidateRequiredSpotName' + language]) {
            this.spotDetailSelected['isValidateLengthSpotName' + language] = !this.validateFullsize(
              this.spotDetailSelected.spotName[language],
              1,
              50
            );
          }

          if (
            ((this.languageKey == 'en' && language != 'en') || (this.languageKey == 'jp' && language != 'ja')) &&
            (this.spotDetailSelected['isValidateRequiredSpotName' + language] ||
              this.spotDetailSelected['isValidateLengthSpotName' + language])
          ) {
            this.spotDetailSelected.isShowSpotName = true;
          }
        }

        if (this.ticketInformationSelected?.length > 0) {
          if (prop.includes('tiedTicketId')) {
            let indexTied = Number(prop.charAt(12)); // number of characters of 'tiedTicketId'
            this.spotDetailSelected[`isValidateRequiredTiedTicketId${indexTied}`] = this.ticketInformationSelected[indexTied].ticketId
              ? false
              : true;
          }

          if (prop.includes('tiedTicketNote')) {
            const matches = prop.match(/(\d+)tiedTicketNote(\w+)/);
            if (matches) {
              let numberBefore = Number(matches[1]);
              let charactersAfter = matches[2];
              if (this.ticketInformationSelected[numberBefore]?.note[charactersAfter]?.trim()?.length) {
                this.spotDetailSelected[`${numberBefore}isValidateLenghtTiedTicketNote${charactersAfter}`] = !this.validateFullsize(
                  this.ticketInformationSelected[numberBefore]?.note[charactersAfter],
                  1,
                  1000
                );
              } else {
                this.spotDetailSelected[`${numberBefore}isValidateLenghtTiedTicketNote${charactersAfter}`] = false;
              }
              if (
                ((this.languageKey == 'en' && charactersAfter != 'en') || (this.languageKey == 'jp' && charactersAfter != 'ja')) &&
                this.spotDetailSelected[`${numberBefore}isValidateLenghtTiedTicketNote${charactersAfter}`]
              ) {
                this.ticketInformationSelected[numberBefore].isShowNote = true;
              }
            }
          }
        }
    }
  }

  /**
   * validateFullsize
   * @param input
   * @param minLength
   * @param maxLength
   * @returns
   */
  validateFullsize(input: string, minLength: number, maxLength: number): boolean {
    if (input.length < minLength || input.length > maxLength) {
      return false;
    }
    return true;
  }

  /**
   * Get title item
   * @param msg
   * @param param
   * @param param2
   */
  getStringTranslate(msg: string, param: string, param2?: string): string {
    const dataString = param2
      ? Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`, `${param2}`)
      : Helper.formatString(this.translateService.instant(`ticket-editor.combination.${msg}`), `${param}`);
    return dataString;
  }

  /**
   * edit spot
   */
  editSpot(): void {
    if (!this.spotSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-spot')
        }
      });
      return;
    } else if (this.isEditSpot) {
      return;
    }

    this.spotDetailSelected.isShowSpotName = true;
    if (this.ticketInformationSelected?.length) {
      this.ticketInformationSelected?.forEach(e => {
        e.isShowTicketName = true;
        e.isShowNote = true;
      });
      for (let i = 0; i < this.ticketInformationSelected.length; i++) {
        this.listPropValidate.push(`tiedTicketId${i}`);
        this.languages.forEach(element => {
          this.listPropValidate.push(`${i}tiedTicketNote${element.translation_language_code}`);
        });
      }
    }
    this.isEditSpot = true;
    this.setViewInfoSpot();
    this.listTicket.nativeElement.scrollTop = 0;
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * setViewInfoSpot
   */
  setViewInfoSpot() {
    var interval = null;
    interval = setInterval(() => {
      if (this.isEditSpot == true) {
        clearInterval(interval);
        let spotEditHtml = document.getElementById('spot-content-heigh');
        let total = 0;
        if (!this.ticketInformationSelected || this.ticketInformationSelected.length == 0) {
          spotEditHtml.style.maxHeight = '23px';
        } else {
          this.ticketInformationSelected.forEach((element, index) => {
            const tiedTicketDiv = document.getElementById(`tiedTicket${index}`);
            if (tiedTicketDiv) {
              total += tiedTicketDiv.offsetHeight;
            }
          });
          if (total > 727) {
            spotEditHtml.style.maxHeight = '727px';
          } else {
            spotEditHtml.style.maxHeight = `${total + 23 * this.ticketInformationSelected.length}px`;
          }
        }
      } else {
        clearInterval(interval);
        let spotEditHtml = document.getElementById('spot-content-heigh');
        spotEditHtml.style.maxHeight = '727px';
      }
    }, 50);
  }

  /**
   * checkTheMaximumHeightHasBeenExceeded
   * @returns
   */
  checkTheMaximumHeightHasBeenExceeded(): boolean {
    if (!this.isEditSpot) {
      return true;
    } else {
      const spotEditHtml = document.getElementById('spot-content-heigh');
      if (spotEditHtml) {
        const height = spotEditHtml.scrollHeight;
        return height > 727 ? true : false;
      }
    }
  }

  /**
   * delete spot
   */
  deleteSpot(): void {
    if (!this.spotSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.choose-spot')
        }
      });
      return;
    }

    if (this.isEditSpot) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('ticket-editor.confirm-delete-checked-spot'),
          button1: this.translateService.instant('ticket-editor.yes'),
          button2: this.translateService.instant('ticket-editor.btn-no')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let payload = {
          spotId: this.spotSelected.spotId
        };
        this.ticketService.deleteSpotsSequentially(this.informationAccount, [payload]).subscribe(result => {
          this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
            this.listSpot = Helper.convertSpots(data);
            this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
            if (this.inputSearch?.length > 0) {
              this.searchSpot(this.inputSearch);
            }
            if (this.dataFilter.length == 0) {
              this.spotSelected = null;
              this.spotDetailSelected = null;
              this.ticketInformationSelected = null;
            } else {
              this.selectSpot(this.dataFilter[0], null);
              this.scrollToheFirstPositionOfTheSpotList();
            }
          });
        });
      }
    );
  }

  /**
   * changeShowNote
   * @param index
   */
  changeShowNote(index: number): void {
    this.ticketInformationSelected[index].isShowNote = !this.ticketInformationSelected[index].isShowNote;
  }

  /**
   * showSpotName
   */
  showSpotName(): void {
    this.spotDetailSelected.isShowSpotName = !this.spotDetailSelected.isShowSpotName;
  }

  /**
   * showSpotName
   */
  showTicketName(id: number): void {
    const index = this.ticketInformationSelected.findIndex(item => item.id == id);
    this.ticketInformationSelected[index].isShowTicketName = !this.ticketInformationSelected[index].isShowTicketName;
  }

  /**
   * getAppName
   * @param id
   * @returns
   */
  getAppName(id: string): string {
    const app = this.listApp.find(item => item.appId == id);
    return app ? app.appName : '';
  }

  /**
   * getNameTicketId
   * @param id
   * @returns
   */
  getTicket(idTicket: number): Ticket {
    return this.tickets.find(item => item.ticketId == idTicket);
  }

  changeTicket(id: number) {
    const index = this.ticketInformationSelected.findIndex(item => item.id == id);
    const ticketSelected = this.tickets.find(item => item.ticketId == this.ticketInformationSelected[index].ticketId);
    this.ticketInformationSelected[index].ticketName = ticketSelected.ticketName;
  }

  /**
   * checkCharInString
   * @param str
   * @param dataSearch
   * @returns
   */
  checkCharInString(str: string, dataSearch: string): boolean {
    if (str.toLowerCase().includes(dataSearch.toLowerCase())) {
      return true;
    }
    return false;
  }

  /**
   * searchSpot
   * @param inputSearch
   */
  searchSpot(inputSearch: string): void {
    this.dataFilter = [...this.listSpot];
    if (inputSearch != '') {
      this.dataFilter = [
        ...this.listSpot.filter(
          spot =>
            this.checkCharInString(this.languageKey == 'jp' ? spot.spotName.ja : spot.spotName.en, inputSearch) &&
            spot.appId == this.appSelected
        )
      ];
      if (this.dataFilter.length == 0) {
        this.spotSelected = null;
        this.spotDetailSelected = null;
        this.ticketInformationSelected = null;
      } else {
        this.selectSpot(this.dataFilter[0], null);
        this.scrollToheFirstPositionOfTheSpotList();
      }
    } else {
      this.clearInputSearch();
    }
  }

  /**
   * clearInputSearch
   */
  clearInputSearch(): void {
    this.inputSearch = '';
    this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
    if (this.dataFilter.length == 0) {
      this.spotSelected = null;
      this.spotDetailSelected = null;
      this.ticketInformationSelected = null;
    } else {
      this.selectSpot(this.dataFilter[0], null);
    }
  }

  /**
   * add Detail
   * @param property
   */
  public addDetail(property: string): void {
    this.spotDetailSelected[`isDetail${property}`] = !this.spotDetailSelected[`isDetail${property}`];
  }

  /**
   * add Table Ticket Info
   */
  addTableTicketInfo(): void {
    let temp = {};
    this.languages.forEach(element => {
      temp[element.translation_language_code] = '';
    });

    let ticketInformation = new TicketInformation(null, null, _.cloneDeep(temp), _.cloneDeep(temp), true, true);
    this.ticketInformationSelected.push(ticketInformation);
    for (let i = 0; i < this.ticketInformationSelected.length; i++) {
      this.ticketInformationSelected[i].id = i;
    }
    this.listPropValidate.push(`tiedTicketId${this.ticketInformationSelected?.length - 1}`);

    this.languages.forEach(element => {
      this.listPropValidate.push(`${this.ticketInformationSelected?.length - 1}tiedTicketNote${element.translation_language_code}`);
    });
    this.setViewInfoSpot();
    setTimeout(() => {
      this.scrollToBottom();
    }, 0);
  }

  /**
   * deleteTicketInfo
   * @param idTicket
   */
  deleteTicketInfo(idTicket: number): void {
    this.ticketInformationSelected = this.ticketInformationSelected.filter(ticket => ticket.id !== idTicket);
    if (this.spotDetailSelected['isValidateRequiredTiedTicketId' + idTicket]) {
      delete this.spotDetailSelected['isValidateRequiredTiedTicketId' + idTicket];
    }
    this.languages.forEach(element => {
      if (this.spotDetailSelected[`${idTicket}isValidateLenghtTiedTicketNote${element.translation_language_code}`]) {
        delete this.spotDetailSelected[`${idTicket}isValidateLenghtTiedTicketNote${element.translation_language_code}`];
      }
    });
    if (idTicket < this.ticketInformationSelected?.length) {
      const properties = Object.keys(this.spotDetailSelected);
      for (let property of properties) {
        if (property.includes('isValidateRequiredTiedTicketId') && this.spotDetailSelected[property]) {
          let indexTied = Number(property.charAt(30)); // number of characters of 'isValidateRequiredTiedTicketId'
          if (indexTied > idTicket) {
            delete this.spotDetailSelected[property];
          }
          this.spotDetailSelected[`isValidateRequiredTiedTicketId${indexTied - 1}`] = true;
        }

        if (property.includes('isValidateLenghtTiedTicketNote') && this.spotDetailSelected[property]) {
          const matches = property.match(/(\d+)isValidateLenghtTiedTicketNote(\w+)/);
          if (matches) {
            let numberBefore = Number(matches[1]);
            let charactersAfter = matches[2];
            if (numberBefore > idTicket) {
              delete this.spotDetailSelected[property];
              this.spotDetailSelected[`${numberBefore - 1}isValidateLenghtTiedTicketNote${charactersAfter}`] = true;
            }
          }
        }
      }
    }

    for (let i = 0; i < this.ticketInformationSelected?.length; i++) {
      this.ticketInformationSelected[i].id = i;
    }
    this.listPropValidate.splice(this.listPropValidate.indexOf(`tiedTicketId${this.ticketInformationSelected?.length}`), 1);
    this.languages.forEach(element => {
      this.listPropValidate.splice(
        this.listPropValidate.indexOf(`${this.ticketInformationSelected?.length}tiedTicketNote${element.translation_language_code}`),
        1
      );
    });
    this.setViewInfoSpot();
  }

  /**
   * change app Name
   */
  async changeAppId(appId?: string): Promise<void> {
    this.dataService.sendData([Constant.IS_CALL_API_TAB_SPOT, this.appSelected || appId ? true : false]);
    this.inputSearch = '';
    if (!this.appSelected && !appId) {
      return;
    }
    this.listSpot = null;
    this.dataFilter = null;
    this.spotSelected = null;
    this.spotDetailSelected = null;
    this.ticketInformationSelected = null;
    if (appId) {
      let languageTranslations = this.listApp.find(e => e.appId == appId)?.supportedLanguage;
      this.languages = Constant.LANGUAGES_SETTING.filter(e => languageTranslations?.includes(e.translation_language_code));
      this.listPropValidate = [];
      this.languages.forEach(element => {
        this.listPropValidate.push(`SpotName${element.translation_language_code}`);
      });
      this.ticketService.getSpots(this.informationAccount, appId).subscribe(data => {
        this.listSpot = Helper.convertSpots(data);
        this.dataFilter = this.listSpot.filter(e => e.appId == appId);
        if (this.dataFilter.length == 0) {
          this.spotSelected = null;
          this.spotDetailSelected = null;
          this.ticketInformationSelected = null;
        } else {
          this.selectSpot(this.dataFilter[0], null);
        }
      });
      await this.getAllTicket();
    } else {
      let languageTranslations = this.listApp.find(e => e.appId == this.appSelected)?.supportedLanguage;
      this.languages = Constant.LANGUAGES_SETTING.filter(e => languageTranslations?.includes(e.translation_language_code));
      this.listPropValidate = [];
      this.languages.forEach(element => {
        this.listPropValidate.push(`SpotName${element.translation_language_code}`);
      });
      this.ticketService.getSpots(this.informationAccount, this.appSelected).subscribe(data => {
        this.listSpot = Helper.convertSpots(data);
        this.dataFilter = this.listSpot.filter(e => e.appId == this.appSelected);
        if (this.dataFilter.length == 0) {
          this.spotSelected = null;
          this.spotDetailSelected = null;
          this.ticketInformationSelected = null;
        } else {
          this.selectSpot(this.dataFilter[0], null);
        }
      });
      await this.getAllTicket();
    }
  }

  /**
   * addImage
   * @param event
   * @returns
   */
  public async addImage(event: any): Promise<void> {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0];
    if (!selectedFile || !this.mapImageEnvironmentVariables) {
      return;
    }
    if (
      !this.imageTypesAllowed.includes(
        selectedFile?.name
          .split('.')
          .pop()
          ?.toLowerCase()
      )
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.spot-tab.file-not-supported')
        }
      });
      this.fileInput.nativeElement.value = null;
      return;
    }

    if (
      !(await this.validateImage(
        selectedFile,
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_FILE_SIZE_TAB_SPOT),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_WIDTH_TAB_SPOT),
        this.mapImageEnvironmentVariables.get(Constant.MAXIMUM_IMAGE_HEIGHT_TAB_SPOT)
      ))
    ) {
      this.fileInput.nativeElement.value = null;
      return;
    }

    let fileName = selectedFile?.name.substring(0, selectedFile?.name?.lastIndexOf('.'));
    if (fileName.match(Constant.FORMAT)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          textHasLineBreaks: this.translateService.instant('ticket-editor.spot-tab.does-not-contain-special-characters')
        }
      });
      this.fileInput.nativeElement.value = null;
      return;
    }

    this.dialogService.showDialog(
      DialogTrimingComponent,
      {
        data: {
          dataEvent: selectedFile
        }
      },
      res => {
        if (!res) {
          this.fileInput.nativeElement.value = null;
          return;
        }
        this.fileInput.nativeElement.value = null;
        this.image = res[0];
        this.imageCircle = res[1];
        this.spotDetailSelected.spotImage = `${res[0]?.name}`;
        this.spotDetailSelected.spotImageCircle = `${res[1]?.name}`;
        this.spotDetailSelected.cropPosition = res[2];
        this.readFile(res[1]);
      }
    );
  }

  validateImage(file: File, maxFileSize: number, maxWidth: number, maxHeight: number): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(false);
        return;
      }

      if (file?.size > maxFileSize * 1024 * 1024) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: Helper.formatString(
              this.translateService.instant(`ticket-editor.msg.maximum-file-size`),
              `${file?.name}`,
              `${maxFileSize}`
            )
          }
        });
        resolve(false);
        return;
      }

      const img = new Image();
      let imageLoaded = false;

      img.onload = () => {
        imageLoaded = true;
        if (img.width > maxWidth) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-width`),
                `${file?.name}`,
                `${maxWidth}`
              )
            }
          });
          resolve(false);
        } else if (img.height > maxHeight) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: Helper.formatString(
                this.translateService.instant(`ticket-editor.msg.maximum-image-height`),
                `${file?.name}`,
                `${maxHeight}`
              )
            }
          });
          resolve(false);
        } else {
          resolve(true);
        }
      };

      img.onerror = () => {
        if (!imageLoaded) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.common-error')
            }
          });
          resolve(false);
        }
      };

      img.src = URL.createObjectURL(file);
    });
  }

  /**
   *editImage
   * @returns
   */
  editImage() {
    let data;

    let isEdit = this.spotDetailSelected.id ? true : false;

    if (!isEdit) {
      data = {
        dataEvent: this.image,
        cropPosition: this.spotDetailSelected?.cropPosition ? this.spotDetailSelected?.cropPosition : null
      };
    }

    if (isEdit) {
      if (this.image) {
        data = {
          dataEvent: this.image,
          cropPosition: this.spotDetailSelected?.cropPosition ? this.spotDetailSelected?.cropPosition : null
        };
      } else {
        data = {
          data: null,
          isEdit: true,
          dataURL: {
            originalFileURL: this.spotDetailSelected.spotImageURL,
            editedFileURL: this.spotDetailSelected.spotImageCircleURL
          },
          cropPosition: this.spotDetailSelected?.cropPosition ? this.spotDetailSelected?.cropPosition : null
        };
      }
    }

    this.dialogService.showDialog(
      DialogTrimingComponent,
      {
        data
      },
      res => {
        if (!res) {
          this.fileInput.nativeElement.value = null;
          return;
        }
        this.fileInput.nativeElement.value = null;
        this.image = res[0];
        this.imageCircle = res[1];
        this.spotDetailSelected.spotImage = `${res[0]?.name}`;
        this.spotDetailSelected.spotImageCircle = `${res[1]?.name}`;
        this.spotDetailSelected.cropPosition = res[2];
        this.readFile(res[1]);
      }
    );
  }

  duplicate(): void {
    if (!this.spotSelected) {
      return;
    }
    this.isDuplicate = true;
    let spot = _.cloneDeep(this.spotSelected);
    this.listSpot.push(spot);
    this.dataFilter.push(spot);
    this.selectSpot(spot, null);
    this.spotDetailSelected.isShowSpotName = true;
    if (this.ticketInformationSelected?.length) {
      this.ticketInformationSelected?.forEach(e => {
        e.isShowTicketName = true;
        e.isShowNote = true;
      });
      for (let i = 0; i < this.ticketInformationSelected.length; i++) {
        this.listPropValidate.push(`tiedTicketId${i}`);
        this.languages.forEach(element => {
          this.listPropValidate.push(`${i}tiedTicketNote${element.translation_language_code}`);
        });
      }
    }
    this.isEditSpot = true;
    this.setViewInfoSpot();
    this.dataService.sendData([Constant.IS_EDITING_SPOT, this.isEditSpot]);
  }

  /**
   * getFileFromUrl
   * @param url
   * @returns
   */
  getFileFromUrl(url: string, fileName: string): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const file = new File([blob], fileName, { type: 'image/png' });
          resolve(file);
        })
        .catch(error => {
          console.error('Error fetching file:', error);
          reject(error);
        });
    });
  }

  /**
   * getFileNameFromUrl
   * @param url
   * @returns
   */
  convertFileNameFromUrl(url: string, mode?: number): string {
    const filenameWithExtension = url.substring(url.lastIndexOf('/') + 1, url.indexOf('?'));
    const filename = filenameWithExtension.split('/').pop();
    let mediaType = filename.slice(filename.lastIndexOf('.') + 1, filename.length).toLowerCase();
    let mediaName;
    if (mode && mode == this.MODE_GETFILENAMEORIFINAL) {
      mediaName = `${filename.split('_')[0]}_${this.IMAGE_NAME_TS}.${mediaType}`;
    } else {
      mediaName = `${filename.split('_')[0]}_${this.IMAGE_NAME_TS_TRIMING}.${mediaType}`;
    }
    return mediaName;
  }

  /**
   * translateTicket
   */
  translateTicket(): void {
    this.dialogService.showDialog(DialogTranslateTicketComponent, {}, result => {
      this.languages = result;
    });
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ticketService.findAllApplication(this.informationAccount).subscribe(
        res => {
          this.listApp = Helper.convertResApplication(res);
          resolve();
        },
        error => {
          Helper.getErrorTicket(error, this.translateService, this.dialogService);
          reject();
        }
      );
    });
  }

  /**
   * getAllTicket
   * @returns
   */
  async getAllTicket(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.ticketService.getTicketsByAppIdAndInUseOperation(this.informationAccount, this.appSelected, 2).subscribe(
        data => {
          this.tickets = Helper.convertTicket(data);
          resolve();
        },
        error => {
          Helper.getErrorTicket(error, this.translateService, this.dialogService);
          reject();
        }
      );
    });
  }
  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplayApp(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 20) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 35) + '...';
    }
    return value;
  }

  downloadQrSVG(): void {
    if (!this.spotDetailSelected) {
      return;
    }
    const fileNameResponse = `QR_${
      this.languageKey == 'jp' ? this.spotDetailSelected.spotName.ja : this.spotDetailSelected?.spotName.en
    }.svg`;
    const svgContent = this.base64Selected;
    const blob = new Blob([svgContent], { type: 'image/svg+xml' });
    const file = new File([blob], fileNameResponse, {
      type: 'image/svg+xml'
    });
    fileSaver.saveAs(file);
  }

  downloadQrPNG(): void {
    if (!this.spotDetailSelected) {
      return;
    }
    const fileNameResponse = `QR_${
      this.languageKey == 'jp' ? this.spotDetailSelected.spotName.ja : this.spotDetailSelected?.spotName.en
    }.png`;
    this.ticketService.generateQRCodeInPngFormat(this.spotDetailSelected.spotQrCode).subscribe(
      res => {
        const byteCharacters = atob(res);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileNameResponse;
        link.click();
      },
      error => {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.common-error')
          }
        });
      }
    );
  }

  readFile(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.fileData = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  scrollToBottom(): void {
    try {
      this.bottom.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
    } catch (err) {
      console.log(err);
    }
  }

  scrollToheFirstPositionOfTheSpotList(): void {
    try {
      this.theFirstPositionOfTheSpotList.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * filterPropertyByLanguages
   * @param note
   * @param languages
   * @returns
   */
  filterPropertyByLanguages(property: any, languages: any[]): any {
    let languagesMap = languages?.map(e => e?.translation_language_code);
    const filtered: any = {};
    if (property) {
      languagesMap.forEach(element => {
        if (Object.keys(property).includes(element)) {
          filtered[element] = property[element];
        } else {
          filtered[element] = '';
        }
      });
      return filtered;
    } else {
      return property;
    }
  }

  /**
   * convertLanguagesToLanguageStringArray
   * @param languages
   * @returns
   */
  convertLanguagesToLanguageStringArray(languages: Array<any>): Array<string> {
    return this.languages?.map(e => e.translation_language_code);
  }
}

export interface DataURL {
  originalFileURL: string;
  editedFileURL: string;
}
interface Language {
  ja: string; // Japanese language representation
  en: string; // English language representation
}
